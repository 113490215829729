<template>
  <div>
    <section>
      <xlsx-workbook>
        <xlsx-sheet
            :collection="sheet.data"
            v-for="sheet in sheets"
            :key="sheet.name"
            :sheet-name="sheet.name"
        />
        <xlsx-download>
         <v-icon>mdi-download</v-icon>
        </xlsx-download>
      </xlsx-workbook>
    </section>
  </div>
</template>

<script>
import {  XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx/dist/vue-xlsx.es.js"

export default {
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  },
  data() {
    return {
      file: null,
      selectedSheet: null,
      sheetName: null,
    };
  },
  props:{
    sheets: Array,
  },
  methods: {
    addSheet() {
      this.sheets.push({ name: this.sheetName, data: [...this.collection] });
      this.sheetName = null;
    }
  }
};
</script>
